import React from 'react';
import * as P from './parts';
import {Col, Container, Row} from "styled-bootstrap-grid";
import {Trans} from "react-i18next";
import Fade from 'react-reveal';

const SectionDemoPricing = () => {
    return (
      <P.StyledSection>
          <Container>
              <P.StyledTitle>
                  <Trans>demo_page_demo_pricing_title</Trans>
              </P.StyledTitle>
              <Fade>
                  <Row>
                      <Col md={6}>
                          <P.Card>
                              <P.CardTopSide>
                                  <P.CardTitle>
                                      <Trans>demo_page_demo_pricing_individual_demo</Trans>
                                  </P.CardTitle>
                                  <P.CardSubtitle>
                                      <Trans>demo_page_demo_pricing_individual_units</Trans>
                                  </P.CardSubtitle>
                              </P.CardTopSide>
                              <P.CardBottomSide>
                                  <P.CardSuptitle>
                                      <Trans>demo_page_demo_pricing_demo_period</Trans>
                                  </P.CardSuptitle>
                                  <P.CardTextWrapper>
                                      <P.CardText>
                                          <P.BoldText>
                                              <Trans>demo_page_demo_pricing_15_calendar_days</Trans>
                                          </P.BoldText>
                                          <Trans>demo_page_demo_pricing_tenure_begins</Trans>
                                          <P.BoldText>
                                              <Trans>demo_page_demo_pricing_5_days_max_5_units</Trans>
                                          </P.BoldText>
                                          <Trans>demo_page_demo_pricing_of_the_expiration</Trans>
                                          <P.OrangeText>
                                              <Trans>demo_page_demo_pricing_59_per_unit</Trans>
                                          </P.OrangeText>
                                          <Trans>demo_page_demo_pricing_tax_included_fleet</Trans>
                                      </P.CardText>
                                  </P.CardTextWrapper>
                              </P.CardBottomSide>
                          </P.Card>
                      </Col>
                      <Col md={6}>
                          <P.Card>
                              <P.CardTopSide>
                                  <P.CardTitle>
                                      <Trans>demo_page_demo_pricing_business_demo</Trans>
                                  </P.CardTitle>
                                  <P.CardSubtitle>
                                      <Trans>demo_page_demo_pricing_business_units</Trans>
                                  </P.CardSubtitle>
                              </P.CardTopSide>
                              <P.CardBottomSide>
                                  <P.CardSuptitle>
                                      <Trans>demo_page_demo_pricing_demo_period</Trans>
                                  </P.CardSuptitle>
                                  <P.CardTextWrapper>
                                      <P.CardText>
                                          <P.BoldText>
                                              <Trans>demo_page_demo_pricing_calendar_month</Trans>
                                          </P.BoldText>
                                          <Trans>demo_page_demo_pricing_tenure_begins</Trans>
                                          <P.BoldText>
                                              <Trans>demo_page_demo_pricing_5_days_5_plus_units</Trans>
                                          </P.BoldText>
                                          <Trans>demo_page_demo_pricing_of_the_expiration</Trans>
                                          <P.OrangeText>
                                              <Trans>demo_page_demo_pricing_59_per_unit</Trans>
                                          </P.OrangeText>
                                          <Trans>demo_page_demo_pricing_tax_included_pro</Trans>
                                      </P.CardText>
                                  </P.CardTextWrapper>
                              </P.CardBottomSide>
                          </P.Card>
                      </Col>
                  </Row>
              </Fade>
              <Fade>
                  <P.AdditionalInfo>
                      <P.AdditionalInfoTitle>
                          <Trans>demo_page_demo_pricing_built_for_you</Trans>
                      </P.AdditionalInfoTitle>
                      <P.AdditionalInfoText>
                          <Trans>demo_page_demo_pricing_from_white_labelling</Trans>
                          <Trans>demo_page_mobd</Trans>
                          <Trans>demo_page_demo_pricing_sales_team_via_email</Trans>
                          <P.OrangeLink href={"mailto:sales@codahead.com"}>
                              <Trans>demo_page_demo_pricing_email</Trans>
                          </P.OrangeLink>
                          <Trans>demo_page_demo_pricing_for_a_truly_tailormade_solution</Trans>
                      </P.AdditionalInfoText>
                  </P.AdditionalInfo>
              </Fade>
          </Container>
      </P.StyledSection>
    );
}

export default SectionDemoPricing;