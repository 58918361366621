import React, {useState} from 'react';
import {Accordion, Select} from "../../partials";
import * as P from './parts';
import {Trans} from "react-i18next";
import {shippingData} from "./shippingData";

const AccordionReturning = () => {
    const [selectedCountry, setSelectedCountry] = useState('Poland');

    return (
        <Accordion title={'demo_page_returning_title'}>
            <>
                <P.StyledText>
                    <Trans>demo_page_returning_returns_within</Trans>
                    <Select
                        placeholder={'Select Country'}
                        list={Object.keys(shippingData)}
                        currentItem={selectedCountry}
                        setItem={setSelectedCountry}
                    />
                    <Trans>demo_page_returning_are_facilitated</Trans>
                    <P.BoldText>{shippingData[selectedCountry]}</P.BoldText>.
                </P.StyledText>
                <P.StyledText>
                    <Trans>demo_page_returning_please_contact</Trans>
                    <P.StyledLink to={'/support/'}>
                        <Trans>demo_page_returning_mobd_support</Trans>
                    </P.StyledLink>
                </P.StyledText>
                <P.StyledSubtitle>EU</P.StyledSubtitle>
                <P.StyledText margin={'5px 0'}>
                    <Trans>demo_page_returning_please_return</Trans>
                    <P.BoldText>
                        <Trans>demo_page_returning_mobd_hardware</Trans>
                    </P.BoldText>
                    <Trans>demo_page_returning_within_5_days</Trans>
                    <P.BoldText>
                        <Trans>demo_page_returning_mobd_device</Trans>
                    </P.BoldText>
                    <Trans>demo_page_returning_at_the_nearest_dhl</Trans>
                    <P.StyledExternalLink href={'https://mydhl.express.dhl/en/schedule-pickup.html#/schedule-pickup#label-reference'} target={'_blank'}>
                        <Trans>demo_page_returning_here</Trans>.
                    </P.StyledExternalLink>
                </P.StyledText>
            </>
        </Accordion>
    )
}

export default AccordionReturning;