import React from 'react';
import {AccordionDemonstrationPeriod, AccordionDetailed, AccordionReturning, AccordionSecurityDeposit} from "../index";

const SectionInformation = () => {
    return (
        <>
            <AccordionDetailed />
            <AccordionDemonstrationPeriod />
            <AccordionReturning />
            <AccordionSecurityDeposit />
        </>
    )
}

export default SectionInformation;