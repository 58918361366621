export const shippingData = {
    'United States': 'FedEx',
    'Canada': 'FedEx',
    'Australia': 'FedEx',
    'Albania': 'DHL',
    'Andorra': 'DHL',
    'Armenia': 'DHL',
    'Austria': 'DHL',
    'Azerbaijan': 'DHL',
    'Belarus': 'DHL',
    'Belgium': 'DHL',
    'Bosnia and Herzegovina': 'DHL',
    'Bulgaria': 'DHL',
    'Croatia': 'DHL',
    'Cyprus': 'DHL',
    'Czechia': 'DHL',
    'Denmark': 'DHL',
    'Estonia': 'DHL',
    'Finland': 'DHL',
    'France': 'DHL',
    'Georgia': 'DHL',
    'Germany': 'DHL',
    'Greece': 'DHL',
    'Hungary': 'DHL',
    'Iceland': 'DHL',
    'Ireland': 'DHL',
    'Italy': 'DHL',
    'Kazakhstan': 'DHL',
    'Kosovo': 'DHL',
    'Latvia': 'DHL',
    'Liechtenstein': 'DHL',
    'Lithuania': 'DHL',
    'Luxembourg': 'DHL',
    'Malta': 'DHL',
    'Moldova': 'DHL',
    'Monaco': 'DHL',
    'Montenegro': 'DHL',
    'Netherlands': 'DHL',
    'North Macedonia': 'DHL',
    'Norway': 'DHL',
    'Poland': 'DHL',
    'Portugal': 'DHL',
    'Romania': 'DHL',
    'Russia': 'DHL',
    'San Marino': 'DHL',
    'Serbia': 'DHL',
    'Slovakia': 'DHL',
    'Slovenia': 'DHL',
    'Spain': 'DHL',
    'Sweden': 'DHL',
    'Switzerland': 'DHL',
    'Turkey': 'DHL',
    'Ukraine': 'DHL',
    'United Kingdom (UK)': 'DHL',
    'Vatican City (Holy See)': 'DHL',
}