import styled from 'styled-components';
import {Link} from "gatsby-plugin-react-i18next";
import {fonts, colors, animations} from "../../../config";

export const StyledText = styled.div`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  line-height: 1.4;
  margin: ${({margin}) => margin || '20px 0'};
`;

export const StyledSubtitle = styled(StyledText)`
  margin: 0;
  display: inline-block;
  font-weight: bold;
`;

export const BoldText = styled.p`
  margin: 0;
  display: inline-block;
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  line-height: 1.4;
  transition: ${animations.default};
  
  &:hover {
    color: ${colors.orange};
  }
`;

export const StyledExternalLink = styled.a`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  line-height: 1.4;
  transition: ${animations.default};

  &:hover {
    color: ${colors.orange};
  }
`;