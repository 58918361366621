import styled, { keyframes } from "styled-components";
import {Section} from "../../partials";
import {animations, colors, fonts} from "../../../config";
import {Link} from "gatsby-plugin-react-i18next";
import { Field } from "formik";

export const StyledSection = styled(Section)`
  padding: 100px 0 40px;
  
  @media screen and (min-width: 768px) {
    padding: 120px 0 40px;
  }
  
  @media screen and (min-width: 1200px) {
    padding: 160px 0 40px;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 58px;
  color: ${colors.white};
  line-height: 1.16;
  font-family: ${fonts.roboto};
  margin-bottom: 30px;
  
  @media screen and (max-width: 1200px) {
    font-size: 48px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 15px;
    text-align: center;
  }
`;

export const StyledSubtitle = styled.h3`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white};
`;

export const ImgWrapper = styled.div`
  max-width: 560px;
  margin-top: 50px;
  
  @media screen and (max-width: 1199px) {
    max-width: 420px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    max-width: 380px;
  }

  @media screen and (max-width: 576px) {
    max-width: unset;
    width: 255px;
    height: 227px;
    margin: 40px auto 0;
  }
`;

export const StyledImg = styled.img`
  width: ${({widthValue}) => widthValue ? widthValue : '100%'};
  
  @media screen and (max-width: 1200px) {
    display: block;
    margin: 0 auto;
  }
`;

export const StyledForm = styled.form`
`;

export const FormTopSide = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const FormBottomSide = styled.div`
  margin-top: 50px;
  
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const FormContent = styled.div`
  background-color: ${colors.deepBlack};
  padding: 25px;
  margin-right: 50px;
  height: 630px;  

  @media screen and (max-width: 1200px) {
    height: 520px;
  }
  
  @media screen and (max-width: 992px) {
    margin-right: 35px;
    height: 420px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto 40px;
    height: 450px;
  }
  
  @media screen and (max-width: 576px) {
    height: auto;
    padding: 15px;
  }
`;

export const StyledText = styled.div`
  font-size: 16px;
  color: ${colors.white};
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const BoldText = styled(StyledText)`
  font-weight: bold;
  display: inline-block;
`;

export const StyledFieldsWrapper = styled.div`
  width: 420px; 
  
  @media screen and (max-width: 1199px) {
    width: 360px;
  }
  
  @media screen and (max-width: 992px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  }
`;

export const RequiredFieldsText = styled.p`
  font-size: 12px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.9;
  color: ${colors.white};
`;

export const StyledLabel = styled.label`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white};

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const StyledInput = styled(Field)`
  width: 100%;
  padding: 8px 15px;
  height: 45px;
  background-color: #3e3e3e;
  font-size: 13px;
  color: #cccccc;
  outline: none;
  border-width: 1px 1px 1px 1px;
  border-color: #212122;
  
  &:focus {
    border: 1px solid ${colors.white};
  }

  @media screen and (max-width: 992px) {
    padding: 4px 15px;
    height: 38px;
  }
`;

export const StyledError = styled.p`
  color: ${colors.red};
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  line-height: 1.6;
  margin-top: 10px;
  position: absolute;
  bottom: -27px;
  ${({textAlign}) => textAlign || 'left'}: 0;
  width: max-content;
  
  @media screen and (max-width: 1200px) {
    bottom: -23px;
  }

  @media screen and (max-width: 992px) {
    bottom: -20px;
    font-size: 11px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const SmallInputWrapper = styled(InputWrapper)`
  margin: ${({margin}) => margin || '0'};
  max-width: ${({maxWidth}) => maxWidth || '100%'};
  min-width: ${({minWidth}) => minWidth || 'unset'};
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

export const InputError = styled.div`
  margin-top: 3px;
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  color: ${colors.red};
  line-height: 1.6;
`;

export const StyledSelectLabel = styled.label`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white}
`;

export const StyledSelect = styled.select`
  height: 45px;
  padding: 8px 15px;
  background-color: #3e3e3e;
  font-size: 13px;
  color: #cccccc;
  outline: none;
  border-width: 1px 1px 1px 1px;
  border-color: #212122;
  -moz-appearance:none; 
  -webkit-appearance:none; 
  appearance:none;

  &:hover {
    cursor: pointer;
  }
  
  &:focus {
    border: 1px solid ${colors.white};
  }
`;

export const RadioTitle = styled.div`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white};

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const RadioTitleBold = styled(RadioTitle)`
  font-weight: bold;
  display: inline-block;
`;

export const RadioContainer = styled.div`
`;

export const RadioItem = styled.div`
  margin-top: 15px;
  position: relative;
  padding-left: 35px;
  height: 25px;
  display: flex;
  align-items: center;
`;

export const StyledRadioInput = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CustomRadioDot = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: ${colors.deepGrey};
    border-radius: 50%;
  
    &:after {
        position: absolute;
        content: '';
        display: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: ${colors.orange};
        border-radius: 50%;
        width: 8px;
        height: 8px;
    }
`;

export const StyledRadioLabel = styled.label`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  
  ${StyledRadioInput}:checked ~ ${CustomRadioDot}:after {
    display: block;
  }
  
  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  margin: 20px 0 0 10px;
  
  @media screen and (max-width: 768px) {
    max-width: 380px;
    margin: 0 auto;
  }
`;

export const StyledCheckbox = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CustomCheckboxMark = styled.span`
  position: absolute;
  top: -1px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${colors.deepGrey};
  
  &::after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 9px;
    height: 15px;
    border: solid ${colors.orange};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const StyledCheckboxLabel = styled.label`
  color: ${colors.white};
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
  
  ${StyledCheckbox}:checked ~ ${CustomCheckboxMark}:after {
    display: block;
  }
`;

export const EmptyDiv = styled.div`
  width: 35px;
  display: inline-block;
  height: 1px;
`;

export const OptionalInputWrapper = styled.div`
  margin-left: 35px;
  margin-top: 20px;
  position: relative;
`;

export const OptionalInput = styled(StyledInput)`
`;

export const SubmitButton = styled.button`
  font-family: ${fonts.robotoCondensed};
  color: ${colors.white};
  border: 1px solid ${colors.orange};
  font-size: 14px;
  line-height: 1.3;
  padding: 0 75px;
  min-height: 45px;
  min-width: 243px;
  background-color: transparent;
  outline: none;
  margin: 50px auto 0;
  display: block;
  transition: 0.2s all ease-in-out;
  
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    margin: 40px auto 0;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledLoader = styled.div`
  border: 3px solid ${colors.lightGrey}; 
  border-top: 3px solid ${colors.orange}; 
  border-radius: 50%;
  width: 25px;
  margin: 0 auto;
  height: 25px;
  animation: ${spin} 2s linear infinite;
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 8px 15px;
  height: 150px;
  background-color: #3e3e3e;
  font-size: 13px;
  color: #cccccc;
  outline: none;
  border-width: 1px 1px 1px 1px;
  border-color: #212122;
  
  &:focus {
    border: 1px solid ${colors.white};
  }
`;

export const MoreInfo = styled.div`
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1200px) {
    margin: 60px auto 0;
  }

  @media screen and (max-width: 992px) {
    margin: 50px auto 0;
  }
`;

export const MoreInfoText = styled.p`
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.orange};
  margin-bottom: 20px;
`;

export const MoreInfoArrow = styled.div`
  border: solid ${colors.white};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(45deg);
`;

export const PolicyLink = styled(Link)`
  color: ${colors.white};
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  transition: ${animations.default};
  
  &:hover {
    color: ${colors.orange};
  }
`;

export const FormSuccessContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`;

export const FormSuccessMessage = styled.h3`
  font-size: 26px;
  line-height: 1.5;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
  
  @media screen and (max-width: 992px) {
    font-size: 22px;
  }

  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
`;