import React from 'react';
import * as P from './parts';
import {Trans} from "react-i18next";
import {Accordion} from "../../partials";
import { useI18next } from "gatsby-plugin-react-i18next";

const AccordionDetailed = () => {
    const {language} = useI18next();

    return (
        <Accordion title={'demo_page_detailed_title'}>
            <>
                <P.TextWrapper>
                    <P.StyledText>
                        <Trans>demo_page_detailed_following_details</Trans>
                        <P.BoldText>
                            <Trans>demo_page_detailed_mobd_demo_equipment</Trans>
                        </P.BoldText>
                        <Trans>demo_page_detailed_developed_by</Trans>
                    </P.StyledText>
                </P.TextWrapper>
                <P.TextWrapper>
                    <P.StyledText>
                        <Trans>demo_page_detailed_thank_you</Trans>
                        <P.BoldText>
                            <Trans>demo_page_mobd</Trans>
                        </P.BoldText>
                        <Trans>demo_page_detailed_we_are_pleased_to_offer</Trans>
                        <P.BoldText>
                            <Trans>demo_page_mobd</Trans>
                        </P.BoldText>
                        <Trans>demo_page_detailed_hardware_supported</Trans>
                        { language !== 'pl' &&
                          <P.BoldText>
                              <Trans>demo_page_mobd</Trans>
                          </P.BoldText>
                        }
                        <Trans>demo_page_detailed_cloud_platform</Trans>
                    </P.StyledText>
                </P.TextWrapper>
                <P.TextWrapper>
                    <P.StyledText>
                        <Trans>demo_page_detailed_codahead_does_not_assume</Trans>
                    </P.StyledText>
                </P.TextWrapper>
                <P.TextWrapper>
                    <P.StyledText>
                        <Trans>demo_page_detailed_we_at_codahead</Trans>
                        <P.StyledLink href={'mailto:sales@codahead.com'}>sales@codahead.com</P.StyledLink>
                    </P.StyledText>
                </P.TextWrapper>
            </>
        </Accordion>
    )
}

export default AccordionDetailed;