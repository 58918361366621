import * as React from "react"
import Page from "../components/Page";
import { Copyright, Footer, Nav, Partners } from "../components/partials";
import {PopupDemo, SectionDemo, SectionDemoPricing, SectionInformation} from "../components/demo";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import Cookies from 'js-cookie';

const DemoPage = () => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef(null);

    const handleOpenPopup = () => {
        const modalViewed = Cookies.get('modalViewed');
        if(!modalViewed) {
            setIsOpen(true);
        }
    }

    const handleClosePopup = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if(isOpen) {
            document.getElementsByTagName("html")[0].style.overflow = "hidden";
        } else {
            document.getElementsByTagName("html")[0].style.overflow = "auto";
        }
    }, [isOpen])

    return (
        <Page>
            <SEO title={t('page_title_request_a_demo')} />
            <Nav />
            <SectionDemo handleOpenPopup={handleOpenPopup} />
            <SectionDemoPricing />
            <SectionInformation />
            <Footer />
            <Partners />
            <Copyright />
            <CSSTransition
                in={isOpen}
                timeout={200}
                classNames='mobile__menu-list'
                unmountOnExit
            >
                <PopupDemo
                    handleClosePopup={handleClosePopup}
                    popupRef={popupRef}
                    isOpen={isOpen}
                />
            </CSSTransition>
        </Page>
    )
};

export default DemoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;