import styled from 'styled-components';
import {fonts, colors} from "../../../config";

export const StyledText = styled.div`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;
  color: ${colors.white};
`;

export const PeriodRow = styled.div`
  display: flex;
  align-items: baseline;
  margin: 20px 0;
`;

export const PeriodColumn = styled.p`
  min-width: 150px;
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: ${colors.orange};
  
  @media screen and (max-width: 576px) {
    min-width: 110px;
    margin-left: 15px;
  }
`;

export const PeriodText = styled(StyledText)`
  display: inline-block;
`;

export const BoldText = styled(PeriodText)`
  font-weight: bold;
`;

export const OrangeLink = styled.a`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;
  color: ${colors.orange};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;