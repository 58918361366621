import styled from 'styled-components';
import {fonts, colors} from "../../../config";

export const TextWrapper = styled.div`
  margin-top: 30px;
`;

export const StyledText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-family: ${fonts.nunitoSans};
  color: ${colors.white};
`;

export const BoldText = styled.p`
  display: inline-block;
  font-weight: bold;
`;

export const StyledLink = styled.a`
  font-weight: 600;
  color: ${colors.orange};
`;