import styled from 'styled-components';
import {fonts, colors} from "../../../config";

export const StyledText = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.4;
  color: ${colors.white};
  margin: ${({margin}) => margin || '20px 0'};
`;

export const BoldText = styled.p`
  font-weight: bold;
  display: inline-block;
`;

export const EquipmentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({margin}) => margin || 'unset'};
`;

export const EquipmentTitle = styled.p`
  min-width: 150px;
  color: ${colors.orange};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  font-family: ${fonts.nunitoSans};
  
  @media screen and (max-width: 576px) {
    min-width: 110px;
    margin-left: 15px;
  }
`;

export const EquipmentText = styled(StyledText)`
`;

export const StyledOrangeText = styled(StyledText)`
  color: ${colors.orange};
  font-weight: bold;
`;

export const StyledList = styled.ol`
  list-style-type: decimal;
`;

export const StyledListItem = styled.li`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.7;
  margin-left: 15px;
  color: ${colors.white};
`;

export const StyledExternalLink = styled.a`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.7;
  color: ${colors.white};
  transition: ${colors.orange};
  text-decoration: none;
  
  &:hover {
    cursor: pointer;
    color: ${colors.orange};
    text-decoration: underline;
  }
`;

export const ExternalBlockLink = styled(StyledExternalLink)`
  line-height: 1.4;
`;