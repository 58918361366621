import React from 'react';
import {Accordion} from "../../partials";
import * as P from './parts';
import {Trans} from "react-i18next";

const AccordionDemonstrationPeriod = () => {
    return (
        <Accordion title={'demo_page_demonstration_title'}>
            <>
                <P.StyledText>
                    <Trans>demo_page_demonstration_standard_demonstration</Trans>
                </P.StyledText>
                <P.PeriodRow>
                    <P.PeriodColumn>
                        <Trans>demo_page_demonstration_column_individual</Trans>
                    </P.PeriodColumn>
                    <P.PeriodText>
                        <Trans>demo_page_demonstration_text_15_days</Trans>
                        <Trans>demo_page_demonstration_text_tenure_begins</Trans>
                        <P.BoldText>
                            <Trans>demo_page_mobd</Trans>
                        </P.BoldText>
                        <Trans>demo_page_demonstration_text_device</Trans>
                    </P.PeriodText>
                </P.PeriodRow>
                <P.PeriodRow>
                    <P.PeriodColumn>
                        <Trans>demo_page_demonstration_column_business</Trans>
                    </P.PeriodColumn>
                    <P.PeriodText>
                        <Trans>demo_page_demonstration_text_1_month</Trans>
                        <Trans>demo_page_demonstration_text_tenure_begins</Trans>
                        <P.BoldText>
                            <Trans>demo_page_mobd</Trans>
                        </P.BoldText>
                        <Trans>demo_page_demonstration_text_device</Trans>
                    </P.PeriodText>
                </P.PeriodRow>
                <P.StyledText>
                    <Trans>demo_page_demonstration_or_as_agreed</Trans>
                    <Trans>demo_page_mobd</Trans>
                    <Trans>demo_page_demonstration_sales_representative</Trans>
                    <Trans>demo_page_mobd</Trans>
                    <Trans>demo_page_demonstration_sales_team</Trans>
                    <P.OrangeLink href={'mailto:sales@codahead.com'}>sales@codahead.com</P.OrangeLink>
                    <Trans>demo_page_demonstration_for_further_information</Trans>
                </P.StyledText>
            </>
        </Accordion>
    );
}

export default AccordionDemonstrationPeriod;