import styled from "styled-components";
import {animations, colors, fonts} from "../../../config";

export const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  
  @media screen and (max-width: 576px) {
    background-color: rgba(0, 0, 0, 0.9);
  }
`;

export const Modal = styled.div`
  z-index: 15;
  background-color: ${colors.extraGrey};
  border-radius: 25px;
  padding: 50px;
  position: relative;
  margin: 30px;
  max-width: 1200px;
  max-height: 80vh;
  width: 50vw;
  
  @media screen and (max-width: 1280px) {
    width: 70vw;
  }

  @media screen and (max-width: 576px) {
    width: 90vw;
    padding: 20px;
    max-height: 90vh;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: ${colors.lightGrey};
  background-color: transparent;
  border: none;
  outline: none;
  transition: ${animations.default};
  
  &:hover {
    color: ${colors.white};
    cursor: pointer;
  }

  @media screen and (max-width: 576px) {
    top: unset;
    right: unset;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    color: ${colors.white};
    font-size: 31px;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 36px;
      height: 36px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid ${colors.white};
      color: ${colors.white};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const StyledText = styled.p`
  font-family: ${fonts.roboto};
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 95%;
  
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    max-width: 90%;
  }

  @media screen and (max-width: 576px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

export const VideoWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
`;

export const StyledVideo = styled.video`
  width: 100%;
  max-height: 50vh;
`;