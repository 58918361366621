import React, {useEffect} from "react";
import * as P from './parts';
import { Trans } from "react-i18next";

const PopupDemo = ({handleClosePopup, popupRef, isOpen}) => {
    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current?.contains(e.target) && isOpen) {
            handleClosePopup();
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    }, [])

    return (
        <P.Overlay>
            <P.Modal ref={popupRef}>
                <P.CloseBtn onClick={handleClosePopup}>&times;</P.CloseBtn>
                <P.Wrapper>
                    <P.StyledText>
                        <Trans>demo_page_popup_welcome_to_the_future</Trans>
                    </P.StyledText>
                    <P.VideoWrapper>
                        <P.StyledVideo controls autoPlay>
                            <source
                                src={'https://s3.eu-west-1.amazonaws.com/files.codahead.com/Mobd+demo.mp4'}
                                type={'video/mp4'}
                            />
                        </P.StyledVideo>
                    </P.VideoWrapper>
                </P.Wrapper>
            </P.Modal>
        </P.Overlay>
    )
}

export default PopupDemo;