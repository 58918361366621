import React from 'react';
import {Accordion} from "../../partials";
import * as P from './parts';
import {Trans} from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

const AccordionSecurityDeposit = () => {
    const {language} = useI18next();

    return (
        <Accordion title={'demo_page_security_deposit_title'}>
            <>
                <P.StyledText>
                    <Trans>demo_page_security_deposit_119$</Trans>
                    <P.BoldText>
                        <Trans>demo_page_mobd</Trans>
                    </P.BoldText>
                    <Trans>demo_page_security_deposit_receiving_your_return</Trans>
                </P.StyledText>
                <P.StyledText margin={'0px'}>
                    <P.BoldText>
                        <Trans>demo_page_security_deposit_mobd_return_address</Trans>
                    </P.BoldText>
                </P.StyledText>
                <P.StyledText margin={'3px 0'}>Codahead</P.StyledText>
                <P.StyledText margin={'3px 0'}>Rydlówka 20</P.StyledText>
                <P.StyledText margin={'3px 0'}>30-363 Kraków</P.StyledText>
                <P.StyledText margin={'3px 0'}>
                    <P.ExternalBlockLink href={'mailto:office@codahead.com'}>office@codahead.com</P.ExternalBlockLink>
                </P.StyledText>
                <P.StyledText margin={'3px 0'}>
                    <P.ExternalBlockLink tel={'+48533041629'}>(+48) 533 041 629</P.ExternalBlockLink>
                </P.StyledText>
                <P.StyledText margin={'20px 0 0'}>
                    <Trans>demo_page_security_deposit_please_note</Trans>
                    <P.BoldText>
                        <Trans>demo_page_mobd</Trans>
                    </P.BoldText>
                    <Trans>demo_page_security_deposit_hardware_is_sourced</Trans>
                </P.StyledText>
                <P.StyledText>
                    <P.BoldText>
                        <Trans>demo_page_security_deposit_continue_using</Trans>
                    </P.BoldText>
                </P.StyledText>
                <P.EquipmentRow>
                    <P.EquipmentTitle>
                        <Trans>demo_page_security_deposit_individual_title</Trans>
                    </P.EquipmentTitle>
                    <P.EquipmentText margin={'0px'}>
                        <Trans>demo_page_security_deposit_individual_text</Trans>
                    </P.EquipmentText>
                </P.EquipmentRow>
                <P.EquipmentRow margin={'20px 0 0'}>
                    <P.EquipmentTitle>
                        <Trans>demo_page_security_deposit_business_title</Trans>
                    </P.EquipmentTitle>
                    <P.EquipmentText margin={'0px'}>
                        <Trans>demo_page_security_deposit_business_text</Trans>
                    </P.EquipmentText>
                </P.EquipmentRow>
                <P.StyledOrangeText>
                    <Trans>demo_page_security_deposit_what_to_do</Trans>
                </P.StyledOrangeText>
                <P.StyledList>
                    <P.StyledListItem>
                        <Trans>demo_page_security_deposit_immediately_make_note</Trans>
                    </P.StyledListItem>
                    <P.StyledListItem>
                        <Trans>demo_page_security_deposit_take_digital_pictures</Trans>
                    </P.StyledListItem>
                </P.StyledList>
                <P.StyledOrangeText>
                    <Trans>demo_page_security_deposit_phone_support</Trans>
                </P.StyledOrangeText>
                { language !== 'pl' &&
                  <P.StyledText margin={'20px 0 0'}>
                      <Trans>demo_page_security_deposit_us_and_canada</Trans>
                      <P.StyledExternalLink href={'tel:+14086270079'}>+1 408 627 0079</P.StyledExternalLink>
                  </P.StyledText>
                }
                <P.StyledText margin={'0px'}>
                    <Trans>demo_page_security_deposit_europe</Trans>
                    <P.StyledExternalLink href={'+48536008632'}>+48 536 008 632</P.StyledExternalLink>
                </P.StyledText>
            </>
        </Accordion>
    )
}

export default AccordionSecurityDeposit;