import styled from 'styled-components';
import {GeneralTitle} from "../../generalStyles";
import {Section} from "../../partials";
import {fonts, colors} from "../../../config";

export const StyledSection = styled(Section)``;

export const StyledTitle = styled(GeneralTitle)`
  text-align: center;
  margin-bottom: 40px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Card = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding: 48px 24px;
  border-radius: 8px;
  background-color: ${colors.ashen};
  min-height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 460px;
    padding: 36px 18px;
    margin: 40px auto 0;
    min-height: unset;
  }
`;

export const CardTopSide = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.demiGrey};
`;

export const CardTitle = styled.h4`
  text-align: center;
  font-family: ${fonts.nunitoSans};
  font-size: 26px;
  font-weight: 600;
  line-height: 1.3;
  color: ${colors.white};
`;

export const CardSubtitle = styled.p`
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  font-weight: 600;
  color: ${colors.orange};
  text-align: center;
  margin-top: 5px;
`;

export const CardBottomSide = styled.div`
  margin-top: 50px;
`;

export const CardSuptitle = styled.p`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  font-weight: 600;
  color: ${colors.white};
  margin-bottom: 12px;
`;

export const CardTextWrapper = styled.div`
  padding-left: 35px;

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }
`;

export const CardText = styled.p`
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  line-height: 1.6;
  color: ${colors.white};
`;

export const BoldText = styled.span`
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  line-height: 1.6;
  color: ${colors.white};
  font-weight: bold;
`;

export const OrangeText = styled.span`
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  line-height: 1.6;
  color: ${colors.white};
  color: ${colors.orange};
  font-weight: bold;
`;

export const AdditionalInfo = styled.div`
  margin-top: 60px;
  text-align: center;
`;

export const AdditionalInfoTitle = styled.p`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.4;
  color: ${colors.orange};
  font-weight: 600;
`;

export const AdditionalInfoText = styled.p`
  max-width: 900px;
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.4;
  margin: 0 auto;
  padding: 0 15px;
`;

export const OrangeLink = styled.a`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.4;
  color: ${colors.orange};
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

